<template>
	<app-categories
		@click="category => openModalFromCat(category)"
		:is-panel-active="sideContent"
	/>
</template>
<script>
import { categoryRoutes } from '../means/categories';
import { actions, mutations } from '@superpagos/mf-store';
import AppCategories from '@/components/AppCategories';

export default {
	name: 'CategoriesView',
	components: { AppCategories },
	data() {
		return {
			sideContent: false
		};
	},
	methods: {
		async openModalFromCat(category) {
			const { id, internalName, products } = category;
			/**
			 * Verificar si la categoria esta excluida en el modal
			 * en dado caso abrir la categoria directamente
			 */
			const checkCategory = await this.$store.dispatch(
				actions.SELL_CHECK_CATEGORY_EXCLUDED,
				{ categoryId: id }
			);

			if (!checkCategory) {
				this.$store.dispatch(actions.SELL_OPEN_PRODUCTS_MODAL, {
					context: this,
					categoryId: id
				});
				return;
			}

			await this.$store.commit(mutations.SET_SELL_SELECTED_CATEGORY_ID, 2);
			await this.$store.dispatch(actions.UPDATE_SELL_SELECTED_PRODUCT, {
				category
			});
			await this.$store.dispatch(actions.SET_SELL_SELECTED_CATEGORY, {
				category
			});

			/**
			 * Validar si la categoria es facturas direccionar a la ruta correspondiente
			 */

			this.selectRouteToLoad(id, internalName, products);
			// this.$router.push({ name: id === 120 ? 'sellBills' : 'sellProduct' })
		},

		selectRouteToLoad(cateogryId, internalName, products) {
			const { route, useParams } = categoryRoutes.find(
				cat => cat.id === cateogryId || cat.name === 'default'
			);
			let productId = products?.length === 1 ? products[0]?.id : 0;
			const params = { id: productId, internalName };
			this.$router.push({ name: route, params: useParams ? params : {} });
		}
	}
};
</script>
