import './set-public-path';
import Vue from 'vue';
import vuex from 'vuex';
import { Vuelidate } from 'vuelidate';
import singleSpaVue from 'single-spa-vue';
import BootstrapVue from 'bootstrap-vue';
import store from '@superpagos/mf-store';
import router from './router';
import services, { MixinService } from '@superpagos/mf-services';
import {
	AppLoader,
	AppInput,
	AppButton,
	AppModal
} from '@superpagos/mf-custom-components';
import App from './App.vue';
// import { initSentry } from './boot/sentry';

Vue.use(vuex);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.mixin({ methods: MixinService });

services.ApiService.init(store);
services.ApiServiceRefacilOld.init(store);
services.ApiServiceSell.init(store);
services.ApiServiceRequirement.init(store);
services.ApiServiceDataAnalytics.init(store);

Vue.component('app-loader', AppLoader);
Vue.component('app-input', AppInput);
Vue.component('app-button', AppButton);
Vue.component('app-modal', AppModal);

Vue.config.productionTip = false;
var Store = store;

// initSentry({ vue: Vue });

Vue.directive('can', {
	bind(el, binding) {
		var user = Store.state.auth.user;
		if (user.Rol.permissions.indexOf(binding.value) == -1) {
			el.style.display = 'none';
		}
	}
});

const vueLifecycles = singleSpaVue({
	Vue,
	appOptions: {
		store,
		render: h => h(App),
		router
	}
});

export { default as version } from './version';
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
