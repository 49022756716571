<template>
	<section class="box-sells">
		<div class="d-lg-none mt-4 mb-3 flex justify-center">
			<app-bags></app-bags>
		</div>

		<app-view-layout
			:CompPrincipal="sellPrimary"
			:CompSecondary="sellSecondary"
			:optionsMenuResponsive="[categoryName || 'Vender', lastTransactionsTitle]"
			:props="propsData"
		/>

		<div v-if="sellShowProductsModal">
			<appProducts @closed="hideModal" />
		</div>

		<app-bill-transaction-data ref="bill" />
	</section>
</template>
<script>
import { mapGetters } from 'vuex';
import { mutations } from '@superpagos/mf-store';
import {
	AppBags,
	AppViewLayout,
	AppBillTransactionData
} from '@superpagos/mf-custom-components';

import sellPrimary from '@/views/SellPrimary';
import sellSecondary from '@/views/SellSecondary';
import appProducts from '@/components/AppProducts';

export default {
	name: 'MainLayout',
	components: { AppViewLayout, AppBags, AppBillTransactionData, appProducts },
	data() {
		return {
			sellPrimary,
			sellSecondary,
			categoryName: '',
			lastTransactionsTitle: 'Últimas ventas',
			propsData: {
				resume: 'Últimas ventas'
			},
		};
	},
	mounted() {},
	computed: {
		...mapGetters(['sellShowProductsModal', 'billData'])
	},
	watch: {
		billData() {
			this.$bvModal.show('transacctionData');
		},
		$route: {
			handler() {
				this.calculateSecondOptionTitle()
			},
			immediate: true
		}
	},
	methods: {
		hideModal() {
			this.$store.commit(mutations.SET_SELL_PRODUCTS_MODAL, false);
		},
		calculateSecondOptionTitle() {
			let productInternalName = this.$route.params.internalName;
			import(
				`@/features/productViewByInternalName/modules/${productInternalName}/data/secondaryView.schema.js`
			)
				.then(result => {
					this.lastTransactionsTitle =
						result?.default?.optionTitle || 'Últimas ventas';
				})
				.catch(() => {
					this.lastTransactionsTitle = 'Últimas ventas';
				});
		}
	}
};
</script>
<style lang="scss">
.box-sells {
	width: 100%;

	.go-back {
		width: 40px;
		height: 40px;
		cursor: pointer;

		img {
			transform: rotate(90deg);
		}
	}

	.sells-box-products {
		width: 100%;
		transition: width 0.25s ease-in-out;
	}

	.sells-box-register {
		width: 100%;
	}

	section .sells-content {
		padding: 30px;
		width: 95%;
		height: 97%;
		// overflow-y: auto;
		background: #fff;
		// box-shadow: 39px 42px 18px -15px rgba(0, 0, 0, 0.05);
	}

	section > div::-webkit-scrollbar {
		display: none;
	}

	@media (min-width: 1024px) {
		display: flex;
		min-height: 100%;
	}

	@media (max-width: 1024px) {
		section .sells-content {
			margin: 0;
			width: 100%;
			height: 90vh;
			padding: 1rem 1.5rem 6rem;
		}

		.sells-box-component {
			width: 100%;
			display: flex;
			flex-direction: column;
		}

		.sells-box-component > div {
			width: 100%;
		}
	}
}
</style>
