<template>
	<div>
		<AppModal size="xl" id="products" height="90vh" @closed="resetModalData">
			<div class="flex justify-center items-center h-full" v-if="isLoading">
				<app-loader type="primary" />
			</div>
			<div v-else>
				<div>
					<div
						class="products-card-header flex flex-wrap justify-content-between align-items-center"
					>
						<div class="col-6 col-xxl-5">
							<p class="products-card-title"> Selecciona un producto </p>
						</div>
						<div
							class="col-6 col-xxl-7 d-md-flex d-xxl-flex justify-content-end"
						>
							<div class="categories-top-bar">
								<template v-if="categoriesTopBar.length">
									<div
										class="d-me-3"
										v-for="(value, index) in categoriesTopBar"
										:key="index"
										:class="value.order && `order-${value.order}`"
									>
										<app-categories-icon
											:index="index"
											:category="value"
											:dense="true"
											@click="
												() => {
													active = 2;
													selectProductV2({ category: value });
												}
											"
										/>
										<AppButton
											:title="value.name"
											@click="
												() => {
													active = 2;
													selectProductV2({ category: value });
												}
											"
											:clase="
												[value.buttonVariant || 'outline-black', 'px-4'].join(
													' '
												)
											"
										></AppButton>
									</div>
								</template>
								<div
									class="categories-top-bar__search-toggle"
									v-if="!showSearchBar"
									@click="showSearchBar = !showSearchBar"
								>
									<img src="../../assets/search.png" alt="" />
								</div>
							</div>

							<div class="products-card-header__search-desktop">
								<AppSearch
									extraClass="input-data-provider"
									type="text"
									v-model="query"
									v-on:keyup="searchProduct()"
									@input="output => (query = output)"
								></AppSearch>
							</div>
						</div>

						<div
							class="products-card-header__search-mobile col-12"
							v-if="showSearchBar"
						>
							<AppSearch
								extraClass="input-data-provider"
								type="text"
								v-model="query"
								v-on:keyup="searchProduct()"
								@input="output => (query = output)"
							></AppSearch>
						</div>
					</div>

					<div class="product-nav-content scroll-none">
						<ul class="product-nav-header">
							<li
								v-for="(value, index) in categories"
								:key="index"
								@click="openCategory(value)"
								v-bind:class="{
									'product-active-nav': selectedCategoryId === value._id,
									hidden: value.excludedFromNav
								}"
							>
								<div class="navigation">
									<a>{{ value.name }}</a>
									<hr class="product-selected" />
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="products-content-modal scroll-none mt-4">
					<div class="content-modal col-12">
						<!-- Mostrar las subcategorias -->
						<div v-if="!showSms && currentCategory.length > 0">
							<div
								v-if="
									currentCategory[0].subCategories ||
									currentCategory[0].products
								"
							>
								<div
									class="mb-4"
									v-for="(item, index) in currentCategory"
									:key="index"
								>
									<div>
										<div>
											<p class="title-category">
												{{ item.name }}
											</p>
										</div>
										<!-- recorrer productos y mostrarlos -->
										<div class="products-card-body scroll-none">
											<template
												v-for="(product, i) in validateField(item, 'render')"
											>
												<div
													:key="i"
													class="card-content"
													:class="product.internalName"
													v-if="!filterProducts(product)"
													@click="
														selectProductV2({
															category: item,
															product,
															subcategoryName: item.name
														})
													"
												>
													<!-- class="product-logo text-center" -->
													<app-product-image
														:index="index"
														:product="product"
													></app-product-image>
													<!-- <img
														:style="[
															product.productPrices &&
															product.productPrices.lenght
																? { filter: 'grayscale(100%)' }
																: { filter: 'grayscale(0%)' }
														]"
														:src="`https://assets.refacil.co/providers/${product.image}`"
														class="product-logo text-center"
														onError="this.src='https://assets.refacil.co/providers/default.png'"
													/> -->
													<!-- <div
														v-bind:class="{
															'text-start': product.price != undefined,
															'text-center': product.price == undefined
														}"
													>
														<p>{{ product.name }}</p>
														<div
															class="products-card-body__amount"
															v-if="product.amount"
														>
															<b>Valor:</b>
															<span>$ {{ money(product.amount) }}</span>
														</div>
													</div> -->
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
							<div v-else>
								<div>
									<div class="products-card-body scroll-none">
										<div
											class="mb-4 card-content"
											v-for="(item, index) in currentCategory"
											:key="index"
											@click="selectProductV2({ category: item })"
										>
											<!-- <app-product-image
												:index="index"
												:product="item"
											></app-product-image> -->
											<img
												:style="[
													item.productPrices && item.productPrices.lenght
														? { filter: 'grayscale(100%)' }
														: { filter: 'grayscale(0%)' }
												]"
												class="product-logo text-center"
												:src="`https://assets.refacil.co/providers/${item.image}`"
												onError="this.src='https://assets.refacil.co/providers/default.png'"
											/>
											<div>
												<p> {{ item.name }} </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AppModal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { actions } from '@superpagos/mf-store';
import AppProductImage from './AppProductImage.vue';

import {
	AppModal,
	AppButton,
	AppSearch,
	AppLoader
} from '@superpagos/mf-custom-components';

import productsToFilter from './filterProducts';
import AppCategoriesIcon from '@/components/AppCategoriesIcon';

const categoryExcludeFromAll = {
	// deposito y retiro transfiya
	194: true,
	195: true
};

const categoriesExcludedFromNav = {
	120: true,
	101: true,
	...categoryExcludeFromAll
};

const DISABLED_CATEGORIES = {
	collectionAgreements: 175
};

const TOP_BAR_CATEGORIES_ATTRIBUTES = {
	bills: {
		buttonVariant: 'primary',
		order: 2
	},
	sms: {
		buttonVariant: 'outline-black',
		name: 'SMS',
		order: 1
	}
};

export default {
	name: 'app-products',
	components: {
		AppModal,
		AppButton,
		AppSearch,
		AppCategoriesIcon,
		AppLoader,
		AppProductImage
	},
	data() {
		return {
			isLoading: false,
			inputResponse: null,
			active: null,
			activeCategory: null,
			query: '',
			showSms: false,
			productSms: {},
			categories: [],
			categoriesTopBar: [],
			currentCategory: [],
			currentCategoryName: '',
			categoriesExport: [],
			// for filter
			productsCategory: [],
			subsProducts: [],
			productsFound: [],
			timeOutSearch: 0,
			showSearchBar: false
		};
	},
	computed: {
		...mapGetters(['user', 'sellSelectedCategoryId']),
		selectedCategoryId() {
			return this.active || this.sellSelectedCategoryId;
		}
	},

	mounted() {
		let categories = [];
		let _categories = this.user.modules
			.find(e => e.id == 1)
			.categories.filter(e => this.isCategoryEnabled(e.id));
		_categories.forEach(category => {
			if (category.id === 1) {
				category.subCategories.forEach(subCategory => {
					const { id, name, internalName } = subCategory;
					categories.push({
						...category,
						name,
						_id: id,
						internalName,
						// fix search bug
						subCategories: [subCategory]
					});
				});
			} else {
				categories.push({ ...category, _id: category.id });
			}
		});
		// Para categorias sin subcategorias mapear asi:
		categories.forEach(category => {
			let _category;
			// Check if is excluded from the tabs
			category.excludedFromNav = categoriesExcludedFromNav[category.id];
			category.excludedFromAll = categoryExcludeFromAll[category.id];
			// Check: Si la categoria no tiene subcategoria
			if (
				category.subCategories ||
				category.internalName.toLowerCase() === 'sms'
			) {
				_category = category;
			} else {
				// Agrupar items en una subcategoria
				_category = {
					...category,
					subCategories: [{ ...category }],
					products: null
				};
			}
			this.categories.push(_category);
			// Add item to top bar menu if is excluded from tabs
			if (_category.excludedFromNav && !_category.excludedFromAll) {
				let categoryAux = {
					..._category,
					...TOP_BAR_CATEGORIES_ATTRIBUTES[_category.internalName.toLowerCase()]
				};

				this.categoriesTopBar.push(categoryAux);
			}
		});

		this.searchProduct();
	},
	methods: {
		searchProduct() {
			if (!this.query) {
				// Search initial category
				const _activeId = this.sellSelectedCategoryId;
				const category = this.categories.find(
					category => category._id === _activeId
				);
				if (category) this.openCategory(category);
				return true;
			}

			this.showSms = false;
			this.currentCategory = [];
			this.productsFound = [];

			// this.searchBills();

			this.categories.some((category, index) => {
				let dataForSearch = this.validateField(category);
				this.searchIn(dataForSearch, index, category.internalName);
			});
		},

		openCategory(category) {
			this.active = category._id;
			this.activeCategory = category;

			if (category.internalName.toLowerCase() === 'sms') {
				category.products[0].internalNameFather = category.internalName;
				this.productSms = category.products[0];
				this.showSms = true;
			} else {
				this.showSms = false;
				this.currentCategory = this.validateField(category);
				this.currentCategory.forEach(product => {
					if (product.subCategories || product.products) {
						let validateProduct = this.validateField(product);
						validateProduct.forEach(e => {
							e.internalNameFather = category.internalName;
						});
					} else {
						product.internalNameFather = category.internalName;
					}
				});
			}
		},

		searchBills() {
			if (this.query.length >= 3) {
				clearTimeout(this.timeOutSearch);
				this.timeOutSearch = setTimeout(() => {
					this.$store
						.dispatch(actions.SEARCH_BILLS, { query: this.query })
						.then(result => {
							//refact-internalName must be in each agreement
							let response = result.agreements;
							result.agreements.forEach((e, i) => {
								response[i].internalName = 'bills';
							});
							//refact-internalName must be in each agreement

							this.currentCategory.push({
								subCategories: response,
								name: 'Facturas'
							});
						})
						.catch(err => {
							console.error(err);
						});
				}, 300);
			}
		},

		searchIn(dataForSearch, index, internalname) {
			this.subsProducts = [];
			dataForSearch.some(sub => {
				let dataForFilter = this.validateField(sub);
				dataForFilter.filter(products => {
					this.compareProducts(products, internalname, sub.id);
					this.addProducts(index);
				});
			});
		},

		compareProducts(product, internalName, idSub) {
			this.productsCategory = [];
			if (product.name.toLowerCase().includes(this.query.toLowerCase())) {
				product.internalNameFather = internalName;
				this.productsCategory.push(product);
				if (product.products || product.subCategories) {
					let dataForFilterSub = this.validateField(product);
					dataForFilterSub.some((sub, i) => {
						if (sub.products) {
							sub.products.some((subPackages, index) => {
								this.addSecondProducts(
									product,
									subPackages,
									idSub,
									index,
									internalName
								);
							});
						} else {
							this.addSecondProducts(product, sub, idSub, i, internalName);
						}
					});
				}
			}
		},

		addProducts(index) {
			let flag = 0;
			if (this.productsCategory.length > 0) {
				flag++;
				this.active = flag != 1 ? -1 : index;
				this.productsFound.push(...this.productsCategory);

				let productName = 'Productos';
				this.currentCategory = [
					{
						subCategories: this.productsFound,
						name: productName
					}
				];
			}

			let packageExist = this.currentCategory.some(e => e.name === 'Paquetes');

			if (this.subsProducts.length > 0 && !packageExist) {
				this.currentCategory.push({
					subCategories: this.subsProducts,
					name: 'Paquetes'
				});
			}
		},

		addSecondProducts(product, sub, idSub, index, internalName) {
			// Agrega los paquetes a mostrar en el buscador
			this.subsProducts.push(
				Object.assign(sub, {
					idProduct: product.id,
					idSub: idSub,
					subcategory: index,
					image: product.image,
					internalNameFather: internalName
				})
			);
		},

		async selectProductV2({ category, product, subcategoryName }) {
			this.isLoading = true;
			await this.$store.dispatch(actions.UPDATE_SELL_SELECTED_PRODUCT, {
				category,
				product,
				subcategoryName,
				categories: this.categories
			});

			const { name } = this.$route;
			const routeName = category.id === 120 ? 'sellBills' : 'sellProduct';
			/**
			 * Validación de ruta:
			 * Si la ruta es igual no hacer enrutamiento
			 */
			if (name !== routeName) this.$router.push({ name: routeName });
			this.$bvModal.hide('products');
			this.isLoading = false;
		},

		selectProduct(item, subcategory) {
			let data;
			let categoryFinded;
			if (item.subcategory != undefined) {
				categoryFinded = this.findCategoryByInternalName(
					item.internalNameFather
				);
				data = this.findProductById(categoryFinded, item);
			} else {
				data = item;
			}

			data.selectedProduct = { id: item.id, subcategory: item.subcategory };
			data.categoryName = subcategory || this.activeCategory.name;
			data.categoryId = this.activeCategory.id;
			data._categoryId = this.activeCategory._id;
			data.internal = {
				internalName: data.internalName,
				internalNameFather: item.internalNameFather || data.internalName
			};
			data.productPrices = [];

			this.query = '';
			this.searchProduct();
			this.$emit('productChange', { data });
			this.$bvModal.hide('products');
		},

		validateField(field) {
			let data = [];
			if (field.products) data = field.products;
			if (field.subCategories) data = field.subCategories;
			data = Array.isArray(data) ? data : [data];
			return data;
		},

		findCategoryByInternalName(internalNameFather) {
			let category = this.categories.find(
				category => category.internalName == internalNameFather
			);
			return category;
		},

		findProductById(category, item) {
			let data = [];
			let dataForFilter = this.validateField(category);
			dataForFilter.some(product => {
				if (product.id == item.idSub) {
					dataForFilter = this.validateField(product);
					data = dataForFilter.find(p => p.id == item.idProduct);
				}
			});
			return data;
		},

		filterProducts(product) {
			let flag = false;
			if (product?.products?.length) return flag;
			Object.values(productsToFilter).map(e => {
				if (e.includes(product.id)) {
					flag = true;
				}
			});
			return flag;
		},

		resetModalData() {
			this.query = '';
			this.$emit('closed');
			// this.searchProduct();
		},

		isCategoryEnabled(categoryId) {
			return !Object.values(DISABLED_CATEGORIES).includes(categoryId);
		}
	}
};
</script>

<style lang="scss" scoped>
.products-content-modal {
	height: 70%;
}

.content-modal > h1 {
	font-size: 50px;
	font-weight: bold;
	margin-bottom: 30px;
}

.content-modal label {
	font-size: 20px;
	font-weight: 400;
	margin-top: 23px;
	line-height: 1em;
	margin-bottom: 6px;
}

.content-modal .form-control {
	height: 53px;
	border-radius: 8px;
	border: 1px solid #d8d8d8;
	font-size: 20px;
	font-weight: 400;
}

.product-nav-header {
	padding-left: 0;
	padding-bottom: 0.5rem;
	margin: 0;
	list-style: none;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	border-bottom: 2px solid #efeff0;

	hr {
		position: absolute;
		bottom: -10px;
		z-index: 999999;
		left: 0;
		margin: auto;
		display: block;
		width: 0;
	}
}

.navigation {
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
	position: relative;

	a {
		color: #cccccc !important;
		font-size: 13px;
		font-weight: bold;
		text-decoration: none;
	}
}

.navigation:hover {
	a {
		transition: all 0.3s ease;
		color: black !important;
	}

	hr {
		transition: all 0.3s ease;
		height: 4px;
		background-color: var(--primaryColor);
		border-radius: 25px;
		opacity: 100%;
		width: 100%;
		border: 0;
	}
}

.product-active-nav {
	a {
		color: black !important;
	}

	hr {
		height: 4px;
		background-color: var(--primaryColor);
		border-radius: 25px;
		opacity: 100%;
		width: 100%;
		border: 0;
	}
}

.title-category {
	font-size: 15px;
	font-weight: 700;
}

.products-card-body {
	display: flex;
	flex-wrap: wrap;
	padding: 0 !important;
}

.card-content {
	cursor: pointer;
	width: 13.6%;
	margin: 15px;
	max-width: 300px;
	min-width: 117px;

	&.packages img {
		background-image: url('../../assets/products/packages/package.png');
		background-size: 70% auto;
		background-repeat: no-repeat;
		background-position: right bottom;
	}

	&:hover {
		p,
		.products-card-body__amount {
			color: #000000;
		}

		.product-logo {
			border: 1px solid #00ceee;
		}
	}

	p {
		margin: 9px auto 0;
		transition: color 0.3s ease-in-out;
		// text
		text-align: center;
		line-height: 1.1;
		font-weight: 700;
		font-size: 12px;
		color: #656466;
	}

	.products-card-body__amount {
		padding-top: 0.5rem;
		// text
		font-weight: 400;
		font-size: 12px;
		color: #656466;
	}

	.product-logo {
		width: 100%;
		height: 52px;
		background-color: white;
		padding: 10px 20px;
		object-fit: contain;
		border-radius: 7px;
		border: 1px solid #fff;
		transition: border-color 0.3s ease-in-out;
		box-shadow: 0 2px 6px rgba(72, 138, 255, 0.2);
		font-size: 10px;
	}
}

.products-card-body {
	justify-content: start;
}

.card-categorie {
	width: 140px;
	height: 50px;
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
}

.products-card-title {
	line-height: 1.1;
	font-size: 25px;
	font-weight: bolder;
}

.products-card-header {
	margin-bottom: 1rem;

	div > div > input {
		border-radius: 10px;
		padding-right: 40px;
	}
}

.text-sms p:first-child {
	font-size: 20px;
	font-weight: 600;
}

.text-sms p:last-child {
	font-size: 15px;
}

.categories-top-bar {
	display: flex;
	align-items: center;
}

@media (min-width: 768px) {
	.title-category {
		font-size: 18px;
	}

	.categories-top-bar {
		gap: 1rem;
		.re-sell-categories__item-icon {
			display: none;
			visibility: hidden;
		}

		&__search-toggle {
			display: none;
			visibility: hidden;
		}
	}

	.products-card-header__search-desktop {
		padding-left: 1rem;
	}

	.products-card-header__search-mobile {
		display: none;
		visibility: hidden;
	}

	.card-content {
		&.packages img {
			background-size: 30% auto;
		}
	}
}

@keyframes autoslide {
	0% {
		margin-left: 0;
	}
	50% {
		margin-left: -20px;
	}
	100% {
		margin-left: 0px;
	}
}

@media (max-width: 1199px) {
	.products-card-body {
		display: flex;
		justify-content: start;
		flex-wrap: nowrap;
		padding: 0 !important;
		animation-name: autoslide;
		animation-duration: 1.5s;
		animation-timing-function: linear;
	}

	.card-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		margin: 5.45px;
		max-width: 69.82px;
		min-width: 69.82px;

		p {
			font-size: 10px;
		}

		.product-logo {
			filter: grayscale(0%);
			width: 69.82px;
			height: 69.82px;
		}
	}
}

@media (max-width: 1250px) {
	.product-nav-content {
		.product-nav-header {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-start;
		}

		.navigation > a {
			white-space: nowrap;
		}
	}
}

@media (max-width: 768px) {
	#products {
		.products-content-modal,
		.modal-content,
		.content-app-modal {
			height: auto;
			max-height: 90vh;
		}
	}

	.products-card-title {
		font-size: 12px;
	}

	.card-content {
		.product-logo {
			padding: 0.5rem;
		}
	}

	.navigation {
		a {
			font-size: 12px;
		}
	}

	.categories-top-bar {
		justify-content: end;
		gap: 0.5rem;

		.buttons {
			display: none;
			visibility: hidden;
		}

		&__search-toggle {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 36px;
			height: 36px;
			border-radius: 4px;
			border: 1px solid #d8d8d8;

			img {
				width: 50%;
				height: auto;
			}
		}
	}

	.products-card-header {
		padding-top: 2rem;
	}

	.products-card-header__search-desktop {
		display: none;
		visibility: hidden;
	}

	.products-card-header__search-mobile {
		.app-search-icon {
			width: 15px;
		}
	}
}
</style>
