<template>
	<div id="mf-sells-app">
		<div class="mf-app-container">
			<router-view />
		</div>
		<AppModal id="modal3FA" size="md">
			<form
				class="w-full h-[225px] sm:h-[197px]"
				v-on:submit.prevent="sellAction(sellParams)"
			>
				<h1
					class="text-center my-3 font-semibold sm:font-normal text-xl sm:text-2xl"
					>Ingresa la segunda clave</h1
				>
				<div class="w-full flex flex-col sm:px-16">
					<AppInput
						type="password"
						class="content-fields mt-2"
						extraClass="input-data-provider"
						labelClass="label-data-provider"
						v-model="password3FA"
					></AppInput>

					<p
						class="text-option-forgot font-normal text-sm"
						@click="showForgotSecondPasswordModal(true)"
						>Olvidé mi segunda clave</p
					>

					<div
						class="box-btn text-center mt-auto flex flex-row justify-between pt-3"
						:class="loading && 'justify-center'"
					>
						<app-button
							v-if="!loading"
							extraClass="primary-gradient px-5 my-5"
							v-on:click="close3FA"
							clase="secondary"
							width="119px"
							title="Cancelar"
						>
						</app-button>

						<app-button
							extraClass="primary-gradient px-5 my-5"
							type="submit"
							clase="primary"
							width="119px"
							title="Confirmar"
							:isLoading="loading"
						>
						</app-button>
					</div>
				</div>
			</form>
		</AppModal>

		<ForgotSecondPasswordModal
			v-if="forgotSecondPassword"
			@closed="showForgotSecondPasswordModal(false)"
		/>
	</div>
</template>

<script>
// import appSells from './views/Sells.vue';
import { mapGetters } from 'vuex';
import {
	AppModal,
	AppInput,
	AppButton
} from '@superpagos/mf-custom-components';
import ForgotSecondPasswordModal from '@/features/secondPassword/components/ForgotSecondPasswordModal';
// Update app version
import { brandReducer } from '@superpagos/mf-store';
import version from './version';

export default {
	name: 'App',
	mounted() {
		brandReducer.setGlobalVersion({ app: 'sells', version });
		console.log('Prueba de funcionamiento de cache en sells');
	},
	components: {
		// appSells,
		AppModal,
		AppInput,
		AppButton,
		ForgotSecondPasswordModal
	},
	data() {
		return {
			password3FA: '',
			loading: false,
			forgotSecondPassword: false
		};
	},
	methods: {
		close3FA() {
			// this.$refs.modal3FA.hide();
			this.$bvModal.hide('modal3FA');

			this.sellParams.reject({ message: 'Venta cancelada' });
		},
		sellAction(sellParams) {
			if (this.loading) return;
			
			let data = {
				...sellParams.data,
				_password: this.password3FA
			};
			this.loading = true;
			sellParams.ApiService.post(sellParams.route, data)
				.then(result => {
					return sellParams.resolve(result);
				})
				.catch(err => {
					return sellParams.reject(err);
				})
				.finally(() => {
					this.loading = false;
					// this.$refs.modal3FA.hide();
					this.$bvModal.hide('modal3FA');
					this.password3FA = '';
				});
		},
		showForgotSecondPasswordModal(showValue) {
			this.forgotSecondPassword = showValue;
		}
	},
	computed: {
		...mapGetters(['brand', 'user', 'sellParams', 'news', 'lastBuy'])
	},
	watch: {
		sellParams(a) {
			// if use second password
			if (this.user.useSecondPassword) {
				// this.$refs.modal3FA.show();
				this.$bvModal.show('modal3FA');
			} else {
				this.sellAction(a);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
#mf-sells-app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	// z-index: 200;
	.sells {
		margin: 10px 10px;
		background-color: aqua;
		border-radius: 8px;
	}
}
#modal3FA .modal-content {
	height: 50%;
}
.text-option-forgot {
	color: var(--primaryColor);
	cursor: pointer;
}

.text-option-forgot:hover {
	text-decoration: underline;
}
</style>
