<template>
	<section
		class="re-sell-categories"
		:class="{ 'is-panel-active': isPanelActive }"
	>
		<div class="re-sell-categories__title">
			Vender
			<div class="tooltip-info" :data-tooltip="tooltipMessage">!</div>
		</div>

		<div class="re-sell-categories__wrap">
			<!-- v-can="`products.${category.internalName}`" -->
			<div
				v-can="'products.sell'"
				:class="
					brand.name.toLowerCase() !== 'refácil' &&
					getCategoryStyle(index).className
				"
				class="re-sell-categories__item hover:scale-105 transition-all duration-300 ease-in-out"
				:style="getCategoryStyle(index)"
				v-for="(category, index) in categories"
				:key="category.id"
				@click="$emit('click', category)"
			>
				<div class="re-sell-categories__item-wrap">
					<!-- :class="getCategoryStyle(index).className" -->
					<!-- :style="
						getCategoryStyle(index)
					" -->
					<app-categories-icon :category="category" :index="index" />
					<div
						class="re-sell-categories__item-name"
						:style="{
							color: cardTitleColor(index)
						}"
						>{{ category.name }}</div
					>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { mapGetters } from 'vuex';
import { getCategoryColor, getCategoryIcon } from '@/means/categories';
import AppCategoriesIcon from '@/components/AppCategoriesIcon';

const DISABLED_CATEGORIES = {
	collectionAgreements: 175,
	sms: 101
};

export default {
	name: 'AppCategories',
	components: { AppCategoriesIcon },
	props: ['isPanelActive'],
	data() {
		return {
			getCategoryIcon,
			categories: [],
			tooltipMessage:
				'Encuentra 13 categorías y más de 70 productos y servicios, para que le vendas a tus clientes y aumentes las ganancias de tu negocio.'
		};
	},
	computed: {
		...mapGetters(['user', 'brand'])
	},
	mounted() {
		let categories = this.user.modules
			.find(e => e.id === 1)
			.categories.filter(e => this.isCategoryEnabled(e.id));

		categories.forEach(category => {
			if (category.id === 1) {
				category.subCategories.forEach(subCategory => {
					this.categories.push(subCategory);
				});
			} else {
				this.categories.push(category);
			}
		});
	},
	methods: {
		getCategoryStyle(index) {
			let defaultCardStyle = {
				// border: '2px solid var(--primaryClearColor)',
				backgroundColor: 'var(--primaryClearColor) !important',
				color: 'var(--primaryColor)'
			};

			if (
				this.brand?.config?.skin?.active?.modules?.sells?.categories
					?.categoryCardsStyles
			) {
				return (
					this.brand?.config?.skin?.active?.modules?.sells?.categories
						?.categoryCardsStyles?.[index] || defaultCardStyle
				);
			}

			if (this.brand.name.toLowerCase() !== 'refácil') {
				// return aux2
				return defaultCardStyle;
			}

			const categoryColors = getCategoryColor(index);
			const { color, bgColor, className } = categoryColors || {};
			return { color, backgroundColor: bgColor, className };
		},

		cardTitleColor(index) {
			let defaultTitleColor = '#656466';

			if (
				this.brand?.config?.skin?.active?.modules?.sells?.categories
					?.iconColors?.[index]
			) {
				return this.brand?.config?.skin?.active?.modules?.sells?.categories
					?.iconColors?.[index];
			}

			if (this.brand.name.toLowerCase() !== 'refácil')
				return 'var(--primaryColor)';

			return this.getCategoryStyle(index)?.color || defaultTitleColor;
		},

		isCategoryEnabled(categoryId) {
			return !Object.values(DISABLED_CATEGORIES).includes(categoryId);
		}
	}
};
</script>
<style lang="scss" scoped>
.side-content {
	.re-sell-categories {
		.re-sell-categories__title {
			justify-content: start;
		}

		.re-sell-categories__wrap {
			justify-content: start;
		}
	}
}

.re-sell-categories {
	max-width: 600px;
	margin: 0 auto;
	padding: 2rem 1rem;

	&__title {
		display: flex;
		gap: 7px;
		justify-content: center;
		align-items: center;
		padding-bottom: 2rem;
		// text
		font-weight: 700;
		font-size: 16px;

		.tooltip-info {
			width: 22px;
			height: 22px;
			border-radius: 12px;
			background-color: var(--terciaryColor);
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			color: #ffff;
			font-size: 14px;
			line-height: 16px;
			cursor: pointer;
		}

		.tooltip-info::before,
		.tooltip-info::after {
			--scale: 0;

			position: absolute;
			top: 130px;
			left: 50%;
			transition: 200ms transform;
			transform-origin: bottom center;
			transform: translateX(-50%) translateY(-100%) scale(var(--scale));
		}

		.tooltip-info::before {
			content: attr(data-tooltip);
			padding: 10px;
			width: max-content;
			max-width: 190px;
			opacity: 0.9;
			background: var(--terciaryColor);
			border-radius: 7px;
			font-size: 12px;
			font-weight: 400;
			text-align: center;
		}

		.tooltip-info:hover::before,
		.tooltip-info:hover::after {
			--scale: 1;
		}
	}

	&__wrap {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 12px;
	}

	&__item {
		flex: 0 1 calc(33.33% - 12px);
		padding: 1rem;
		border-radius: 8px;
		cursor: pointer;

		&-icon {
			padding-bottom: 0.5rem;
			svg {
				width: 30px;
				height: 30px;
				margin: 0 auto;
			}
		}

		&-name {
			line-height: 1.1;
			font-size: 8px;
			font-weight: 700;
		}
	}

	/**
   * Desktop only
   */
	@media (min-width: 850px) {
		padding: 1.5rem 3rem 3rem 1rem;

		&__title {
			font-size: 24px;
		}

		&__item {
			padding: 1.5rem 1rem;
			&-name {
				font-size: 12px;
			}
		}
	}

	/**
   * Mobile only
   */
	@media (max-width: 850px) {
		height: auto;
		padding-bottom: 10rem;

		&__title {
			// justify-content: start;
		}
	}
}
.standardCategoryItem {
	// border: 2px solid #d8d8d8;
	// background-color: #f9f9f9 !important;
	border: 2px solid var(--primaryClearColor);
	background-color: var(--primaryClearColor) !important;
}
</style>
