<template>
	<div :data-index="index" :product-id="product.id" class="product-content">
		<div v-if="!isVisible" class="wraper-img"> </div>
		<div
			v-if="isVisible"
			class="flex justify-center items-center wraper-img text-center"
		>
			<img
				:style="[
					product.productPrices && product.productPrices.lenght
						? { filter: 'grayscale(100%)' }
						: { filter: 'grayscale(0%)' }
				]"
				class="product-logo"
				:class="!isLoaded && 'active'"
				:src="`https://assets.refacil.co/providers/${product.image}`"
				onError="this.src='https://assets.refacil.co/providers/default.png'"
			/>
		</div>
		<div
			v-bind:class="{
				'text-start': product.price != undefined,
				'text-center': product.price == undefined
			}"
		>
			<p>{{ product.name }}</p>
			<div class="products-card-body__amount" v-if="product.amount">
				<b>Valor:</b>
				<span>$ {{ money(product.amount) }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Refacil20AppProductImage',

	props: ['product', 'index', 'productsVisibles'],

	data() {
		return {
			isLoaded: false,
			observer: null,
			isVisible: false
		};
	},

	created() {
		this.observer = new IntersectionObserver(this.onElementObserved, {
			root: this.$el,
			threshold: 1.0
		});
	},

	mounted() {
		this.observer.observe(this.$el);
	},

	methods: {
		onElementObserved(entries) {
			entries.forEach(({ target, isIntersecting }) => {
				if (!isIntersecting) {
					return;
				}

				this.observer.unobserve(target);

				this.isVisible = true;
				setTimeout(() => {
					this.isLoaded = true;
				}, 5000);
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.wraper-img {
	width: 100%;
	height: 52px;
	background-color: white;
	padding: 10px 20px;
	object-fit: contain;
	border-radius: 7px;
	border: 1px solid #fff;
	transition: border-color 0.3s ease-in-out;
	box-shadow: 0 2px 6px rgba(72, 138, 255, 0.2);
	font-size: 10px;
	overflow: hidden;
}

.title-category {
	font-size: 15px;
	font-weight: 700;
}

.product-content {
	cursor: pointer;
	width: 100%;
	max-width: 300px;
	min-width: 117px;

	&:hover {
		p,
		.products-card-body__amount {
			color: #000000;
		}

		.wraper-img {
			border: 1px solid var(--primaryColor);
		}
	}

	p {
		margin: 9px auto 0;
		transition: color 0.3s ease-in-out;
		// text
		text-align: center;
		line-height: 1.1;
		font-weight: 700;
		font-size: 12px;
		color: #656466;
	}

	.products-card-body__amount {
		padding-top: 0.5rem;
		// text
		font-weight: 400;
		font-size: 12px;
		color: #656466;
	}

	.product-logo {
		width: 100%;
		height: 52px;
		background-color: white;
		padding: 10px 20px;
		object-fit: contain;
	}
}

.active {
	animation: fadeIn 1s;
	animation-timing-function: ease;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@media (min-width: 768px) {
	.title-category {
		font-size: 18px;
	}

	.product-content {
		&.packages img {
			background-size: 30% auto;
		}
	}
}

@keyframes autoslide {
	0% {
		margin-left: 0;
	}
	50% {
		margin-left: -20px;
	}
	100% {
		margin-left: 0px;
	}
}

@media (max-width: 1199px) {
	.product-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		margin: 5.45px;
		max-width: 69.82px;
		min-width: 69.82px;

		p {
			font-size: 10px;
		}

		.product-logo {
			filter: grayscale(0%);
			width: 69.82px;
			height: 69.82px;
			padding: 0;
		}
	}

	.wraper-img {
		width: 69.82px;
		height: 69.82px;
	}
}

@media (max-width: 1250px) {
	.product-nav-content {
		.navigation > a {
			white-space: nowrap;
		}
	}
}

@media (max-width: 768px) {
	.product-content {
		.wraper-img {
			padding: 0.5rem;
		}
		.product-logo {
			padding: 8px;
		}
	}

	.navigation {
		a {
			font-size: 12px;
		}
	}

	.categories-top-bar {
		justify-content: end;
		gap: 0.5rem;

		&__search-toggle {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 36px;
			height: 36px;
			border-radius: 4px;
			border: 1px solid #d8d8d8;

			img {
				width: 50%;
				height: auto;
			}
		}
	}
}
</style>
