<template>
	<div
		class="re-sell-categories__item-icon"
		:class="isActive && 'bounce-top'"
		@click="handleClick"
	>
		<!-- :class="[{ dense: dense }]" -->
		<!-- :style="getCategoryStyle(index)" -->
		<!-- :class="[{ dense: dense }, getCategoryStyle(index).className]" -->
		<svg
			fill="none"
			viewBox="0 0 36 36"
			xmlns="http://www.w3.org/2000/svg"
			preserveAspectRatio="xMidYMid slice"
		>
			<path
				:d="getCategoryIcon(category.internalName).d"
				:fill-rule="getCategoryIcon(category.internalName).fillRule"
				:clip-rule="getCategoryIcon(category.internalName).clipRule"
				:fill="fillColor"
			/>
		</svg>
	</div>
</template>
<script>
import { getCategoryStyle, getCategoryIcon } from '@/means/categories';
import { mapGetters } from 'vuex';

export default {
	name: 'AppCategoriesIcon',
	props: {
		category: { required: true },
		index: { required: true, default: 1 },
		dense: { default: false }
	},
	data() {
		return {
			getCategoryStyle,
			getCategoryIcon,
			isActive: false
		};
	},
	mounted() {},

	methods: {
		handleClick() {
			this.isActive = true;
			this.$emit('click');
			setTimeout(() => {
				this.isActive = false;
			}, 1000);
		}
	},

	computed: {
		...mapGetters(['brand']),
		fillColor() {

			if (
				this.brand?.config?.skin?.active?.modules?.sells?.categories
					?.iconColors?.[this.index]
			) {
				return this.brand?.config?.skin?.active?.modules?.sells?.categories
					?.iconColors?.[this.index];
			}
			return this.brand.name.toLowerCase() !== 'refácil'
				? this.brand.primaryColor
				: getCategoryStyle(this.index).color;
		}
	}
};
</script>
<style lang="scss">
.re-sell-categories__item-icon {
	padding: 0.5rem;
	border-radius: 4px;

	&.dense {
		svg {
			width: 20px;
			height: 20px;
		}
	}

	svg {
		width: 30px;
		height: 30px;
		margin: 0 auto;
	}
}

.bounce-top {
	animation: bounce-top 0.9s linear both;
}

@keyframes bounce-top {
	5% {
		transform: translateY(-8px);
		animation-timing-function: ease-in;
		opacity: 1;
	}
	24% {
		opacity: 1;
	}
	40% {
		transform: translateY(-4px);
		animation-timing-function: ease-in;
	}
	25%,
	55%,
	75%,
	87% {
		transform: translateY(0);
		animation-timing-function: ease-out;
	}
	100% {
		transform: translateY(0);
		animation-timing-function: ease-out;
		opacity: 1;
	}
}

.productPrimary {
	// background-color: var(--primaryClearColor) !important;
}
</style>
