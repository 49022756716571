import auth from '@/middleware/auth';
import can from '@/middleware/can';

import MainLayout from '@/layout/MainLayout';
import CategoriesView from '@/views/CategoriesView';

const ProductView = () => import('@/views/ProductView');
const BillsView = () => import('@/views/BillsView');
const ProductViewInternalName = () => import('@/views/ProductViewInternalName');
const ValidateTransfiyaProduct = () => import('@/views/transfiya/ValidateTransfiyaProduct.vue');

const routes = [
	{
		path: '/',
		component: MainLayout,
		meta: { middleware: [auth] },
		children: [
			{
				path: '',
				component: CategoriesView,
				name: 'sells',
				meta: { middleware: [auth, can('products.sell')] }
			},
			{
				path: 'producto',
				component: ProductView,
				name: 'sellProduct',
				meta: { middleware: [auth, can('products.sell')] }
			},
			{
				path: 'facturas',
				component: BillsView,
				name: 'sellBills',
				meta: { middleware: [auth, can('products.sell')] }
			},
			{
				path: 'products/:internalName/:id',
				component: ProductViewInternalName,
				name: 'sellProductInternalName',
				meta: { middleware: [auth, can('products.sell')] }
			}
		]
	},
	{
		path: '/validate-transfiya-product',
		component: ValidateTransfiyaProduct,
		name: 'validateTransfiyaProduct',
		meta: { middleware: [auth] },
	}
];

export default routes;
