var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AppModal',{attrs:{"size":"xl","id":"products","height":"90vh"},on:{"closed":_vm.resetModalData}},[(_vm.isLoading)?_c('div',{staticClass:"flex justify-center items-center h-full"},[_c('app-loader',{attrs:{"type":"primary"}})],1):_c('div',[_c('div',[_c('div',{staticClass:"products-card-header flex flex-wrap justify-content-between align-items-center"},[_c('div',{staticClass:"col-6 col-xxl-5"},[_c('p',{staticClass:"products-card-title"},[_vm._v(" Selecciona un producto ")])]),_c('div',{staticClass:"col-6 col-xxl-7 d-md-flex d-xxl-flex justify-content-end"},[_c('div',{staticClass:"categories-top-bar"},[(_vm.categoriesTopBar.length)?_vm._l((_vm.categoriesTopBar),function(value,index){return _c('div',{key:index,staticClass:"d-me-3",class:value.order && `order-${value.order}`},[_c('app-categories-icon',{attrs:{"index":index,"category":value,"dense":true},on:{"click":() => {
												_vm.active = 2;
												_vm.selectProductV2({ category: value });
											}}}),_c('AppButton',{attrs:{"title":value.name,"clase":[value.buttonVariant || 'outline-black', 'px-4'].join(
												' '
											)},on:{"click":() => {
												_vm.active = 2;
												_vm.selectProductV2({ category: value });
											}}})],1)}):_vm._e(),(!_vm.showSearchBar)?_c('div',{staticClass:"categories-top-bar__search-toggle",on:{"click":function($event){_vm.showSearchBar = !_vm.showSearchBar}}},[_c('img',{attrs:{"src":require("../../assets/search.png"),"alt":""}})]):_vm._e()],2),_c('div',{staticClass:"products-card-header__search-desktop"},[_c('AppSearch',{attrs:{"extraClass":"input-data-provider","type":"text"},on:{"keyup":function($event){return _vm.searchProduct()},"input":output => (_vm.query = output)},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)]),(_vm.showSearchBar)?_c('div',{staticClass:"products-card-header__search-mobile col-12"},[_c('AppSearch',{attrs:{"extraClass":"input-data-provider","type":"text"},on:{"keyup":function($event){return _vm.searchProduct()},"input":output => (_vm.query = output)},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1):_vm._e()]),_c('div',{staticClass:"product-nav-content scroll-none"},[_c('ul',{staticClass:"product-nav-header"},_vm._l((_vm.categories),function(value,index){return _c('li',{key:index,class:{
								'product-active-nav': _vm.selectedCategoryId === value._id,
								hidden: value.excludedFromNav
							},on:{"click":function($event){return _vm.openCategory(value)}}},[_c('div',{staticClass:"navigation"},[_c('a',[_vm._v(_vm._s(value.name))]),_c('hr',{staticClass:"product-selected"})])])}),0)])]),_c('div',{staticClass:"products-content-modal scroll-none mt-4"},[_c('div',{staticClass:"content-modal col-12"},[(!_vm.showSms && _vm.currentCategory.length > 0)?_c('div',[(
								_vm.currentCategory[0].subCategories ||
								_vm.currentCategory[0].products
							)?_c('div',_vm._l((_vm.currentCategory),function(item,index){return _c('div',{key:index,staticClass:"mb-4"},[_c('div',[_c('div',[_c('p',{staticClass:"title-category"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',{staticClass:"products-card-body scroll-none"},[_vm._l((_vm.validateField(item, 'render')),function(product,i){return [(!_vm.filterProducts(product))?_c('div',{key:i,staticClass:"card-content",class:product.internalName,on:{"click":function($event){return _vm.selectProductV2({
														category: item,
														product,
														subcategoryName: item.name
													})}}},[_c('app-product-image',{attrs:{"index":index,"product":product}})],1):_vm._e()]})],2)])])}),0):_c('div',[_c('div',[_c('div',{staticClass:"products-card-body scroll-none"},_vm._l((_vm.currentCategory),function(item,index){return _c('div',{key:index,staticClass:"mb-4 card-content",on:{"click":function($event){return _vm.selectProductV2({ category: item })}}},[_c('img',{staticClass:"product-logo text-center",style:([
												item.productPrices && item.productPrices.lenght
													? { filter: 'grayscale(100%)' }
													: { filter: 'grayscale(0%)' }
											]),attrs:{"src":`https://assets.refacil.co/providers/${item.image}`,"onError":"this.src='https://assets.refacil.co/providers/default.png'"}}),_c('div',[_c('p',[_vm._v(" "+_vm._s(item.name)+" ")])])])}),0)])])]):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }