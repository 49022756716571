import { useApi } from '@superpagos/mf-services';

const API = 'core';
const module = 'auth';

const useForgotSecondPassword = () => {
	const generateOtp = async () => {
		const { response, error, request } = useApi(API);
		await request(`${module}/forgotSecondPassword/generateOtp`, {});
		if(response.value) return response.value;
		if(error.value) return error.value;
	};

	const validateOtp = async pin => {
		const { response, error, request } = useApi(API);
		await request(`${module}/forgotSecondPassword/validateOtp`, { pin });
		if(response.value) return response.value;
		if(error.value) return error.value;
	};

	const changeSecondPassword = async data => {
		const { response, error, request } = useApi(API);
		await request(`${module}/changeSecondPassword`, data);
		if(response.value) return response.value;
		if(error.value) return error.value;
	};

	return { generateOtp, validateOtp, changeSecondPassword };
};

export default useForgotSecondPassword;
