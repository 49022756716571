<template>
	<app-modal
		id="forgot-second-password"
		ref="forgotSecondPasswordModal"
		size="md"
		@closed="closeModalForgotPassword"
	>
		<h1
			class="text-center my-3 sm:mb-2 font-semibold sm:font-normal text-xl sm:text-2xl"
			>Recuperar segunda clave</h1
		>
		<template v-if="action === 'confirm-otp'">
			<form class="w-full flex flex-col sm:px-20 h-[195px]">
				<p class="text-center text-sm"
					>Hemos enviado un código único a tu celular. Ingrésalo a
					continuación</p
				>

				<app-input
					type="text"
					class="content-fields mt-2"
					extraClass="input-data-provider"
					labelClass="label-data-provider"
					:error="errorMessage"
					v-model="pin"
				></app-input>

				<p class="text-resend-pin font-normal text-sm" @click="sendOtpMessage"
					>Reenviar código</p
				>

				<div class="flex justify-center mt-3">
					<app-button
						extraClass="primary-gradient px-5"
						clase="primary"
						width="120px"
						title="Confirmar"
						:isLoading="loading"
						@click="handleConfirmOtp"
					>
					</app-button>
				</div>
			</form>
		</template>
		<template v-else-if="action === 'change-password'">
			<form class="w-full h-full h-full flex flex-col sm:px-5">
				<app-input
					type="password"
					class="content-fields"
					label="Ingresa nueva segunda clave"
					extraClass="input-data-provider"
					labelClass="label-data-provider"
					:error="errorMessage"
					v-model="newPasswrod"
				></app-input>
				<span v-if="!error" class="helper-text"
					>Segunda clave debe tener 4 dígitos</span
				>

				<app-input
					type="password"
					class="content-fields mt-2"
					label="Confirma segunda clave"
					extraClass="input-data-provider"
					labelClass="label-data-provider"
					:error="errorMessage"
					v-model="verifyPassword"
				></app-input>

				<div class="flex justify-center">
					<app-button
						extraClass="primary-gradient px-5 my-5"
						clase="primary"
						width="120px"
						title="Confirmar"
						:isLoading="loading"
						@click="handleConfirmNewPassword"
					>
					</app-button>
				</div>
			</form>
		</template>
		<template v-else-if="action === 'success-password'">
			<div
				class="w-full h-[180px] sm:h-[170px] flex flex-col justify-center items-center"
			>
				<p class="w-60 text-center text-[18px] pb-3"
					>Tu segunda clave ha sido recuperada exitosamente</p
				>

				<app-button
					extraClass="primary-gradient px-5 my-5"
					clase="primary"
					width="120px"
					title="Confirmar"
					:isLoading="loading"
					@click="closeModalForgotPassword"
				>
				</app-button>
			</div>
		</template>
	</app-modal>
</template>

<script>
import {
	AppModal,
	AppInput,
	AppButton
} from '@superpagos/mf-custom-components';
import { ACTIONS } from '../enums';
import useForgotSecondPassword from '../hooks/useForgotSecondPassword';

const { generateOtp, validateOtp, changeSecondPassword } =
	useForgotSecondPassword();

export default {
	name: 'ForgotSecondPasswordModal',
	components: { AppModal, AppInput, AppButton },

	data() {
		return {
			action: ACTIONS.confirmOtp,
			error: false,
			loading: false,
			newPasswrod: '',
			pin: '',
			verifyPassword: ''
		};
	},

	created() {
		this.sendOtpMessage();
	},

	mounted() {
		this.showModalForgotPassword();
	},

	methods: {
		async sendOtpMessage() {
			this.loading = true;
			const result = await generateOtp();

			if (result !== true) {
				return this.appNotify.error(result.message);
			}

			this.loading = false;
		},

		async handleConfirmOtp() {
			this.clearError();
			this.loading = true;
			try {
				const result = await validateOtp(this.pin);

				if (result !== true) {
					return this.handleError(result.message);
				}

				this.action = ACTIONS.changePassword;
			} catch (error) {
				this.appNotify.error(error);
			} finally {
				this.loading = false;
			}
		},

		async handleConfirmNewPassword() {
			this.clearError();
			this.loading = true;
			try {
				const data = {
					password: this.newPasswrod,
					verify: this.verifyPassword
				};

				const result = await changeSecondPassword(data);

				if (result.message == 'Ok') {
					this.handleSuccess();
				} else {
					return this.handleError(result.message);
				}
			} catch (error) {
				this.appNotify.error(error.message);
			} finally {
				this.loading = false;
			}
		},

		handleSuccess() {
			this.appNotify.success('Segunda contraseña cambiada exitosamente');
			this.action = ACTIONS.successPassword;
		},

		handleError(message) {
			this.error = true;
			this.errorMessage = message;
			this.appNotify.error(message);
		},

		closeModalForgotPassword() {
			this.$emit('closed');
		},

		showModalForgotPassword() {
			this.$refs.forgotSecondPasswordModal.show();
		},

		clearError() {
			this.error = false;
			this.errorMessage = null;
		}
	}
};
</script>

<style lang="scss">
.text-resend-pin {
	margin-top: -18px;
	color: var(--primaryColor);
	cursor: pointer;
}

.text-resend-pin:hover {
	text-decoration: underline;
}

.helper-text {
	font-size: 11px;
	margin-top: -18px;
}
</style>
