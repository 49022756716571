import { navigateToUrl } from 'single-spa';
export default permission =>
	({ next, Store }) => {
		var user = Store.getters.user;

		if (user.Rol.permissions.indexOf(permission) === -1) {
			//   window.location = "/";
			navigateToUrl('/');
			return;
		}

		return next();
	};
