<template>
	<div class="w-full beauty-scroll" style="max-height: 100%">
		<component v-if="secondaryViewComponent" :is="secondaryViewComponent" ref="secondaryViewComponent"></component>

		<div v-else-if="!secondaryViewComponent">
			<section v-if="!isDataLoaded" class="w-full min-h-screen flex items-center justify-center">
				<AppLoader type="primary"></AppLoader>
			</section>
			<section v-else class="ps-4 pe-4 pb-4 w-full">
				<AppLastTransaction title="Últimas ventas" :sales="salesListModel" :lastTransaction="true"
					@click="showSaleDetails" @dateRange="setValueDates"></AppLastTransaction>
			</section>
		</div>
	</div>
</template>
<script>
import { actions } from '@superpagos/mf-store';
import {
	AppLastTransaction,
	AppLoader
} from '@superpagos/mf-custom-components';
import { mapGetters } from 'vuex'

export default {
	name: 'sell-secondary',
	components: { AppLastTransaction, AppLoader },
	data() {
		return {
			sales: [],
			salesListModel: [],
			isDataLoaded: false,
			secondaryViewComponent: null
		};
	},
	created() {
		this.initTransactions();
		this.getSecondaryViewComponent();
	},
	methods: {
		transformDataToSalesListModel(data) {
			if (!data) return;
			this.sales = data.payload;
			this.salesListModel = data.payload.map(item => ({
				code: item.id,
				number: item.serviceNumber,
				amount: item.amount,
				product: item.Product.name,
				status: item.status
			}));
		},

		createBillDataAdapter(sale) {
			const {
				id,
				Product,
				amount,
				createdAt,
				transactionId,
				serviceNumber,
				share
			} = sale;
			return {
				share,
				messages: [],
				costs: [
					{
						label: 'Valor venta',
						value: amount
					}
				],
				fields: [
					{
						label: 'Producto',
						value: Product.name
					},
					{
						label: 'Fecha',
						value: createdAt
					},
					{
						label: 'Código',
						value: id
					},
					{
						label: 'Código del proveedor',
						value: transactionId
					},
					{
						label: 'Número',
						value: serviceNumber
					}
				]
			};
		},

		async fetchTransactions(data = {}) {
			const res = await this.$store.dispatch(
				actions.FETCH_TRANSACTIONS_LAST_SELLS,
				data
			);
			this.transformDataToSalesListModel(res);
		},

		async initTransactions() {
			if (this.secondaryViewComponent) return;

			try {
				await this.fetchTransactions();
			} catch (error) {
				console.error(error);
			} finally {
				this.isDataLoaded = true;
			}
		},

		setValueDates(value) {
			const dateRange = {
				dateStart: value[0].toISOString().split('T')[0],
				dateEnd: value[1].toISOString().split('T')[0]
			};
			this.fetchTransactions(dateRange);
		},

		showSaleDetails(item) {
			const sale = this.sales.find(e => e.id === item.code);
			const saleAdapted = this.createBillDataAdapter(sale);
			// Set Store billData
			this.$store.dispatch(actions.SAVE_BILLDATA, saleAdapted);
		},

		getSecondaryViewComponent() {
			let productInternalName = this.$route.params.internalName;

			import(
				`@/features/productViewByInternalName/modules/${productInternalName}/components/secondaryViewComponent`
			)
				.then(result => {
					this.secondaryViewComponent = result.default;
				})
				.catch(() => {
					this.secondaryViewComponent = null;
				});
		}
	},
	computed: {
		...mapGetters(['updateLastTransactions'])
	},
	watch: {
		$route: {
			handler() {
				this.secondaryViewComponent = null;

				this.getSecondaryViewComponent();
				this.initTransactions();
			},
			immediate: true
		},
		updateLastTransactions(value) {
			if (!value) return;

			if (this.secondaryViewComponent) {
				this.$refs.secondaryViewComponent.updateTransactions();
			} else {
				this.initTransactions();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.beauty-scroll {
	overflow: scroll;
}

.beauty-scroll::-webkit-scrollbar {
	width: 8px;
	height: 100%;
}

.beauty-scroll::-webkit-scrollbar-thumb {
	background: #c4c4c4;
	border-radius: 30px;
}

.beauty-scroll::-webkit-scrollbar-track {
	border-radius: 5px;
}
</style>
