var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-content",attrs:{"data-index":_vm.index,"product-id":_vm.product.id}},[(!_vm.isVisible)?_c('div',{staticClass:"wraper-img"}):_vm._e(),(_vm.isVisible)?_c('div',{staticClass:"flex justify-center items-center wraper-img text-center"},[_c('img',{staticClass:"product-logo",class:!_vm.isLoaded && 'active',style:([
				_vm.product.productPrices && _vm.product.productPrices.lenght
					? { filter: 'grayscale(100%)' }
					: { filter: 'grayscale(0%)' }
			]),attrs:{"src":`https://assets.refacil.co/providers/${_vm.product.image}`,"onError":"this.src='https://assets.refacil.co/providers/default.png'"}})]):_vm._e(),_c('div',{class:{
			'text-start': _vm.product.price != undefined,
			'text-center': _vm.product.price == undefined
		}},[_c('p',[_vm._v(_vm._s(_vm.product.name))]),(_vm.product.amount)?_c('div',{staticClass:"products-card-body__amount"},[_c('b',[_vm._v("Valor:")]),_c('span',[_vm._v("$ "+_vm._s(_vm.money(_vm.product.amount)))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }